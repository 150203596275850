<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
               <div class="admin_main_block_left">
                    <div><router-link to="/Admin/xueyxj/form"><el-button type="primary" icon="el-icon-plus">添加</el-button></router-link></div>
                   <div><el-button icon="el-icon-caret-right" @click="exports()">导出表格</el-button></div>
                   <div><el-button icon="el-icon-caret-left" ><router-link to="/Admin/xueyxj/forms">导入表格</router-link></el-button></div>
                   <div><el-button  icon="el-icon-caret-left" @click="get_yijfb()">一键分班</el-button></div>
                   <div><el-button icon="el-icon-caret-left" ><router-link to="/Admin/xueyxj/formss">导入表格分班</router-link></el-button></div>
               </div>
                <div class="admin_main_block_right">

                    <div >

                        <el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button>
                    </div>
                </div>
                <br/><br/><br/>
                <div class="admin_main_block_left">

                    <div style="width:115px;">
                        <el-select v-model="where.F_IN_JID_ID" placeholder="请选择基地" @change="selectChange()">
                            <el-option label="请选择基地" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['jid']" :label="v.F_VR_JIDMC" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </div>


                    <div style="width:115px;">
                        <el-select v-model="where.F_IN_YINGQ_ID" placeholder="请选择营期" @change="selectChange()">
                            <el-option label="请选择营期" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['yingq']" :label="v.F_IN_NAME" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </div>

                    <div style="width:115px;">
                        <el-select v-model="where.F_IN_YINGQQX_ID" placeholder="请选择期数" @change="selectChange()">
                            <el-option label="请选择期数" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['yingqx']" :label="v.F_VR_QISHU" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </div>

                    <div style="width:115px;">
                        <el-select v-model="where.F_IN_YINGDBJ" placeholder="请选择班级" @change="selectChange()">
                            <el-option label="请选择班级" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['banj']" :label="v.F_VR_BANJMC" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </div>

                    <div style="width:140px;">
                        <el-select v-model="where.F_IN_ZSLS_ID" placeholder="请选择招生老师" @change="selectChange()">
                            <el-option label="请选择招生老师" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['zsls']" :label="v.F_VR_XINGM" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </div>
                    
                    <div style="width:120px;"><el-input   v-model.trim="where.F_VR_XINGM" placeholder="学员姓名"></el-input></div>
                    <div style="width:120px;"><el-input   v-model.trim="where.F_VR_JIANHRXM" placeholder="监护人姓名"></el-input></div>
                    <div style="width:150px;"><el-input type="number"  v-model.trim="where.F_VR_LIANXFS" placeholder="联系方式"></el-input></div>

                    <div style="width:85px;">
                        <el-input   v-model.trim="where.F_VR_SUS" placeholder="宿舍"></el-input>
                    </div>




                    <!--<div ><el-date-picker format="yyyy-MM-dd HH:mm" v-model="where.times" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker></div>
-->

                    <div style="width:110px;">
                        <el-select v-model="where.F_TI_DINGJZT" placeholder="订金状态">
                            <el-option label="全部" value="0"></el-option>
                            <el-option label="已付定金" value="1"></el-option>
                            <el-option label="未付定金" value="2"></el-option>
                        </el-select>
                    </div>

                    <div style="width:110px;margin-top:10px;">
                        <el-select v-model="where.F_TI_WEIKZT" placeholder="尾款状态">
                            <el-option label="全部" value="0"></el-option>
                            <el-option label="已付尾款" value="1"></el-option>
                            <el-option label="未付尾款" value="2"></el-option>
                        </el-select>
                    </div>





                    <div style="width:85px;margin-top:10px;">
                        <el-input   v-model.trim="where.F_IN_ZUIXNL" placeholder="最小年龄"></el-input>
                    </div>
                    <div style="width:6px;margin-top:10px;padding:0 3px;">
                        -
                    </div>
                    <div style="width:85px;margin-top:10px;">
                        <el-input   v-model.trim="where.F_IN_ZUIDNL" placeholder="最大年龄"></el-input>
                    </div>

                    <div style="width:140px;margin-top:10px;">
                        <el-select v-model="where.F_TI_QIANM" placeholder="请选择签名状态">
                            <el-option label="全部" value="0"></el-option>
                            <el-option label="已签名" value="1"></el-option>
                            <el-option label="未签名" value="2"></el-option>
                        </el-select>
                    </div>


                    <div style="width:140px;margin-top:10px;">
                        <el-select v-model="where.F_TI_SHIFFB" placeholder="请选择是否分班">
                            <el-option label="全部" value="0"></el-option>
                            <el-option label="已分班" value="1"></el-option>
                            <el-option label="未分班" value="2"></el-option>
                        </el-select>
                    </div>

                    <div style="width:140px;margin-top:10px;">
                        <el-select v-model="where.F_TI_SHIFTY" placeholder="请选择是否退营">
                            <el-option label="全部" value="0"></el-option>
                            <el-option label="未退营" value="1"></el-option>
                            <el-option label="已退营" value="2"></el-option>
                        </el-select>
                    </div>

                    <div style="width:500px;margin-top:10px;">
                        <el-date-picker
                                size="small"
                                v-model="where.times"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :default-time="['12:00:00']"
                        >
                        </el-date-picker>
                    </div>



                    <div style="margin-top:10px;"><el-button icon="el-icon-search" @click="get_adv_list()">条件筛选</el-button></div>


                </div>

                <!--<div class="admin_main_block_right" style="margin-top:10px;">
                    <div >
                        <el-button  icon="el-icon-search" @click="get_adv_list()">条件筛选</el-button>
                    </div>
                </div>-->

            </div>
            <div class="admin_table_main">
                <el-table :data="info" @selection-change="handleSelectionChange" >
                    <el-table-column type="selection"></el-table-column>
                    <!-- <el-table-column prop="id" label="#" fixed="left" width="70px"></el-table-column> -->
                    <el-table-column prop="F_IN_ID" label="#"  width="50px"></el-table-column>
                    <!--<el-table-column prop="F_VR_XINGM" label="姓名" width="70px">
                        <template slot-scope="scope">
                            <el-tag type="danger" v-if="scope.row.chongfcount==1">男</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.chongfcount==2">女</el-tag>
                        </template>
                    </el-table-column>-->

                    <el-table-column label="姓名" width="60px">
                        <template slot-scope="scope">
                            <el-tag type="danger" v-if="scope.row.chongfcount==1">{{ scope.row.F_VR_XINGM }}</el-tag>
                            <div  closable="true" v-else-if="scope.row.chongfcount==2">{{ scope.row.F_VR_XINGM }}</div>
                        </template>
                    </el-table-column>



                    <el-table-column prop="F_VR_XUEJH" label="学籍号" width="62px"></el-table-column>
                    <el-table-column prop="F_VR_OPENID" label="openid" width="100px"></el-table-column>
                    <el-table-column label="头像" width="62px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt><img :src="scope.row.F_VR_TOUX||require('@/assets/default_pic.png')" width="50px" height="50px"></dt>
                                <dd class="table_dl_dd_all">{{ scope.row.adv_title }}</dd>
                            </dl>
                        </template>
                    </el-table-column>
                    <el-table-column label="性别" width="50px">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.F_TI_XINGB==1">男</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.F_TI_XINGB==2">女</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="F_IN_NIANL" label="年龄" width="50px"></el-table-column>
                    <el-table-column prop="F_VR_JIANHRXM" label="监护人姓名" width="80px"></el-table-column>
                    <!--<el-table-column prop="F_VR_LIANXFS" label="联系方式"></el-table-column>-->

                    <el-table-column label="联系方式" width="95px">
                        <template slot-scope="scope">
                            <el-tag type="danger" v-if="scope.row.chongfcount==1">{{ scope.row.F_VR_LIANXFS }}</el-tag>
                            <div  closable="true" v-else-if="scope.row.chongfcount==2">{{ scope.row.F_VR_LIANXFS }}</div>
                        </template>
                    </el-table-column>



                    <el-table-column prop="F_VR_GUANX" label="与营员关系"></el-table-column>
                    <el-table-column prop="F_VR_ZSLS_XINGM" label="招生老师"></el-table-column>
                    <el-table-column  label="班级名称">
                        <template slot-scope="scope">
                            <div  v-if="scope.row.F_IN_YINGDBJ==0">-</div>
                            <div type="danger" v-else>{{ scope.row.F_IN_YINGDBJ }}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="定金" width="102px">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.F_TI_DINGJZT==1">已支付{{ scope.row.F_VR_DINGJ }}</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.F_TI_DINGJZT==2">待支付{{ scope.row.F_VR_DINGJ }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="尾款" width="102px">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.F_TI_WEIKZT==1">已支付{{ scope.row.F_VR_WEIK }}</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.F_TI_WEIKZT==2">待支付{{ scope.row.F_VR_WEIK }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否签名" width="75px">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.F_TI_QIANM==1">已签名</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.F_TI_QIANM==2">未签名</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否分班" width="75px">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.F_TI_SHIFFB==1">已分班</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.F_TI_SHIFFB==2">未分班</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="签到签名">
                        <template slot-scope="scope">
                            <el-popover placement="left" trigger="click" width="50">
                                <img :src="scope.row.F_VR_QIANMXYTP||require('@/assets/default_pic.png')" width="100%" />
                                <img
                                        slot="reference"
                                        :src="scope.row.F_VR_QIANMXYTP||require('@/assets/default_pic.png')"
                                        :alt="scope.row.F_VR_QIANMXYTP||require('@/assets/default_pic.png')"
                                        style="max-height: 70px;max-width: 70px; padding: 5px"
                                />
                            </el-popover>

                        </template>
                    </el-table-column>
                    <el-table-column label="入营通知书">
                        <template slot-scope="scope">
                            <el-popover placement="left" trigger="click" width="600">
                                <img :src="scope.row.F_VR_TONGZS||require('@/assets/default_pic.png')" width="100%" />
                                <img
                                        slot="reference"
                                        :src="scope.row.F_VR_TONGZS||require('@/assets/default_pic.png')"
                                        :alt="scope.row.F_VR_TONGZS||require('@/assets/default_pic.png')"
                                        style="max-height: 70px;max-width: 70px; padding: 5px"
                                />
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="入营通知书发放" width="100px">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.F_TI_TONGZSZT==1">已发放</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.F_TI_TONGZSZT==2">未发放</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="收据">
                        <template slot-scope="scope">
                            <el-popover placement="left" trigger="click" width="600">
                                <img :src="scope.row.F_VR_SHOUJ||require('@/assets/default_pic.png')" width="100%" />
                                <img
                                        slot="reference"
                                        :src="scope.row.F_VR_SHOUJ||require('@/assets/default_pic.png')"
                                        :alt="scope.row.F_VR_SHOUJ||require('@/assets/default_pic.png')"
                                        style="max-height: 70px;max-width: 70px; padding: 5px"
                                />
                            </el-popover>
                        </template>
                    </el-table-column>

                    <el-table-column prop="F_IN_CHUANGJSJ" label="创建时间">
                        <template slot-scope="scope">
                            <div v-if="scope.row.F_IN_CHUANGJSJ<=0"> - </div>
                            <div v-else>{{scope.row.F_IN_CHUANGJSJ|formatDate}}</div>
                        </template>
                    </el-table-column>


                    <el-table-column prop="F_VR_GUANGGJH" label="广告计划">
                        <template slot-scope="scope">
                            <div v-if="scope.row.F_VR_GUANGGJH">{{scope.row.F_VR_GUANGGJH}}</div>
                            <div v-else> - </div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="F_VR_GUANGGJH" label="广告流量来源">
                        <template slot-scope="scope">
                            <div v-if="scope.row.F_VR_LIULLY">{{scope.row.F_VR_LIULLY}}</div>
                            <div v-else> - </div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="F_VR_GUANGGJH" label="广告创建时间">
                        <template slot-scope="scope">
                            <div v-if="scope.row.F_VR_GUANGGCJSJ">{{scope.row.F_VR_GUANGGCJSJ}}</div>
                            <div v-else> - </div>
                        </template>
                    </el-table-column>




                    <el-table-column label="操作" fixed="right" width="120px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push({name:'xueyxj_form',params:{id:scope.row.F_IN_ID}})">编辑</el-button>
                            <!-- <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> -->
                        </template>
                    </el-table-column>
                    <div style="margin: 0px" >{!! info->links() !!}</div>
                    <div slot="append">
                        <div class='sum_footer xiaoji' ref='sum_xiaoji'>
                            <div class='sum_footer_unit center' >总金额：{{zongje}}</div>
                            <div class='sum_footer_unit center' >已付定金：{{dingj}}</div>
                            <div class='sum_footer_unit center' >已付尾款：{{weik}}</div>
                            <div class='sum_footer_unit center' >已完成人数：{{yiwcrs}}</div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                        </div>
                        <!--<div class='sum_footer' ref='sum_heji'>
                            <div class='sum_footer_unit center'>合计</div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'>6</div>
                            <div class='sum_footer_unit'>6</div>
                            <div class='sum_footer_unit'>6</div>
                        </div>-->
                    </div>
                </el-table>

                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total" :total="total_data" :page-size="page_size" :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          list:[],
          total_data:0, // 总条数
          page_size:20,
          current_page:1,
          select_id:'',
          adv_position_id:0,
          where:{
              times:[],
              F_IN_JID_ID:'',
              F_IN_YINGQ_ID:'',
              F_IN_YINGQQX_ID:'',
              F_IN_YINGDBJ:'',
              F_IN_ZSLS_ID:'',
              F_VR_XINGM:'',
              F_VR_JIANHRXM:'',
              F_VR_LIANXFS:'',
              F_TI_QIANM:'',
              F_TI_WEIKZT:'',
              F_TI_SHIFFB:'',
              F_IN_ZUIXNL:'',
              F_IN_ZUIDNL:'',
              F_TI_DINGJZT:'',
              F_TI_SHIFTY:'',
              F_VR_SUS:''
          },
          info:[],
          zongje:0,
          dingj:0,
          weik:0,
          yiwcrs:0
      };
    },
    watch: {},
    computed: {},
    methods: {

        adjustWidth() {
            this.$nextTick(() => {
                //        this.$refs.sum_xiaoji.style='width:'+
                if(this.$refs&&this.$refs.sum_xiaoji&&this.refs.sum_heji&&this.$refs.table&&this.$refs.table.$refs.headerWrapper) {
                    var width = getComputedStyle(this.$refs.table.$refs.headerWrapper.querySelector('table')).width;
                    this.$refs.sum_xiaoji.style = 'width:' + width;
                    this.$refs.sum_heji.style = 'width:' + width;
                    Array.from(this.$refs.table.$refs.headerWrapper.querySelectorAll('col')).forEach((n, i) => {
                        this.$refs.sum_xiaoji.children[i].style = 'width:' + n.getAttribute('width') + 'px';
                        this.$refs.sum_heji.children[i].style = 'width:' + n.getAttribute('width') + 'px';
                    });
                }
            })
        },
        getXiaoji(name){
            var sum=0;
            this.tableData.forEach((n,i)=>{
                sum+=parseFloat(n[name]);
            })
            return sum;
        },


        handleSelectionChange:function(e){
            let ids = [];
            e.forEach(v => {
                ids.push(v.F_IN_ID);
            });
            this.select_id = ids.join(',');
        },
        get_yijfb:function(){
            this.$post(this.$api.getYijfb,{}).then(res=>{
                if(res.code == 200){
                    this.get_order_list();
                    return this.$message.success("分班成功");
                }else{
                    return this.$message.error("分班失败");
                }
            });
        },
        get_adv_list:function(){
            /*this.where.page = this.current_page;*/
            let where = {};
            where.page = this.current_page;
            where.params = this.where;
            this.$get(this.$api.getXueyxjList,where).then(res=>{
                this.page_size = res.data.info.per_page;
                this.total_data = res.data.info.total;
                this.current_page = res.data.info.current_page;
                this.list = res.data;
                this.info = res.data.info.data;
                this.zongje = res.data.zongje;
                this.dingj = res.data.dingj;
                this.weik = res.data.weik;
                this.yiwcrs = res.data.yiwcrs;
            })
        },
        exports:function(){

          //  window.open(`http://baidu.com`);

         //  window.open(this.$api.exportXueyxj);
          //window.open(`http://wechat.jiangxinqiye.com/api/excel/daoc?F_IN_JID_ID=${this.where.F_IN_JID_ID}&F_IN_YINGQ_ID=${this.where.F_IN_YINGQ_ID}&F_IN_YINGQQX_ID=${this.where.F_IN_YINGQQX_ID}&F_IN_YINGDBJ=${this.where.F_IN_YINGDBJ}&F_IN_ZSLS_ID=${this.where.F_IN_ZSLS_ID}&F_VR_XINGM=${this.where.F_VR_XINGM}`, '_self');
            if(this.where.times[0]){
                this.where.times[0] = this.where.times[0].toGMTString();
                this.where.times[1] = this.where.times[1].toGMTString();
            }
             // window.open(`${this.$api.exportxuey}?F_IN_JID_ID=${this.where.F_IN_JID_ID}&F_IN_YINGQ_ID=${this.where.F_IN_YINGQ_ID}&F_IN_YINGQQX_ID=${this.where.F_IN_YINGQQX_ID}&F_IN_YINGDBJ=${this.where.F_IN_YINGDBJ}&F_IN_ZSLS_ID=${this.where.F_IN_ZSLS_ID}&F_VR_XINGM=${this.where.F_VR_XINGM}`, '_self');
            //window.open(`http://wechat.jiangxinqiye.com/api/excel/daoc?F_IN_JID_ID=${this.where.F_IN_JID_ID}&F_IN_YINGQ_ID=${this.where.F_IN_YINGQ_ID}&F_IN_YINGQQX_ID=${this.where.F_IN_YINGQQX_ID}&F_IN_YINGDBJ=${this.where.F_IN_YINGDBJ}&F_IN_ZSLS_ID=${this.where.F_IN_ZSLS_ID}&F_VR_XINGM=${this.where.F_VR_XINGM}&timeone=${this.where.times[0]}&timetwo=${this.where.times[1]}`, '_self');

         window.open(`http://houtai.cloudliveshop.com/api/excel/daoc?F_IN_JID_ID=${this.where.F_IN_JID_ID}&F_IN_YINGQ_ID=${this.where.F_IN_YINGQ_ID}&F_IN_YINGQQX_ID=${this.where.F_IN_YINGQQX_ID}&F_IN_YINGDBJ=${this.where.F_IN_YINGDBJ}&F_IN_ZSLS_ID=${this.where.F_IN_ZSLS_ID}&F_VR_XINGM=${this.where.F_VR_XINGM}&F_VR_JIANHRXM=${this.where.F_VR_JIANHRXM}&F_VR_LIANXFS=${this.where.F_VR_LIANXFS}&F_TI_DINGJZT=${this.where.F_TI_DINGJZT}&F_TI_WEIKZT=${this.where.F_TI_WEIKZT}&F_IN_ZUIXNL=${this.where.F_IN_ZUIXNL}&F_IN_ZUIXNL=${this.where.F_IN_ZUIXNL}&F_IN_ZUIDNL=${this.where.F_IN_ZUIDNL}&F_TI_QIANM=${this.where.F_TI_QIANM}&F_TI_SHIFFB=${this.where.F_TI_SHIFFB}&F_TI_SHIFTY=${this.where.F_TI_SHIFTY}&timeone=${this.where.times[0]}&timetwo=${this.where.times[1]}`, '_self');

        },
        // 删除处理
        del:function(F_IN_ID){
            if(this.$isEmpty(F_IN_ID)){
                return this.$message.error('请先选择删除的对象');
            }
            this.$post(this.$api.delXueyxj,{F_IN_ID:F_IN_ID}).then(res=>{
                if(res.code == 200){
                    this.get_adv_list();
                    return this.$message.success("删除成功");
                }else{
                    return this.$message.error("删除失败");
                }
            });
        },
        current_change:function(e){
            this.current_page = e;
            this.get_adv_list();
        },
    },
    created() {
        this.get_adv_list();
    },
    mounted() {
        this.adjustWidth();
        window.addEventListener('resize', this.adjustWidth.bind(this))
    }
};
</script>
<style lang="scss" scoped>
    .sum_footer{
        display:flex;
        display:-webkit-flex;
        line-height:50px;
        color:#606266;
    }
    .sum_footer_unit{
        flex-grow:1;
        -webkit-flex-grow:1;
        text-indent:10px;
        font-size:14px;
        font-weight:bold;
    }
    .sum_footer_unit.center{
        text-align:center;
    }
    .sum_footer.xiaoji{
        border-bottom:1px solid #EBEEF5;
    }

    .admin_main_block_left div {
        float: left;
        margin-right: 2px;
    }

    .el-range-editor--small .el-range-input {
        font-size: 13px;
        width: 120px;
    }




</style>